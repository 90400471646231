import { ethers } from 'ethers'
import getRpcUrl from 'utils/getRpcUrl'
import { ChainId } from 'config/constants/networks'

// const RPC_URL = getRpcUrl()
const ethProvider = new ethers.providers.StaticJsonRpcProvider(getRpcUrl(ChainId.ETHEREUM))
const rinkebyProvider = new ethers.providers.StaticJsonRpcProvider(getRpcUrl(ChainId.RINKEBY))
const bscProvider = new ethers.providers.StaticJsonRpcProvider(getRpcUrl(ChainId.MAINNET))
const bsctestProvider = new ethers.providers.StaticJsonRpcProvider(getRpcUrl(ChainId.TESTNET))
const polygonProvider = new ethers.providers.StaticJsonRpcProvider(getRpcUrl(ChainId.POLYGON))
const mumbaiProvider = new ethers.providers.StaticJsonRpcProvider(getRpcUrl(ChainId.MUMBAI))
const avaxTestnetProvider = new ethers.providers.StaticJsonRpcProvider(getRpcUrl(ChainId.AVAX_TESTNET))

export const simpleRpcProvider = (chainId = 43113) => {
    let provider
    switch (chainId) {
        case ChainId.AVAX_TESTNET:
            provider = avaxTestnetProvider
            // console.log(provider)
            break
        default:
            provider = avaxTestnetProvider
    }

    return provider
}

export default null
