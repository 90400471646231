// import { ChainId } from '@pancakeswap/sdk'

export enum ChainId {
  MAINNET = 56,
  TESTNET = 97,
  ETHEREUM = 1,
  RINKEBY = 4,
  POLYGON = 137,
  MUMBAI = 80001,
  AVAX_TESTNET = 43113,
}

export const NETWORK_URLS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: 'https://bsc-dataseed1.defibit.io',
  [ChainId.TESTNET]: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  [ChainId.ETHEREUM]: 'https://etherscan.io/',
  [ChainId.RINKEBY]: 'https://rinkeby.etherscan.io/',
  [ChainId.POLYGON]: 'https://polygonscan.com/',
  [ChainId.MUMBAI]: 'https://mumbai.polygonscan.com/',
  [ChainId.AVAX_TESTNET]:'https://testnet.snowtrace.io'
}

// export default NETWORK_URLS
