import { useEffect, useState, useRef } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { simpleRpcProvider } from 'utils/providers'
import { ChainId } from 'config/constants/networks'
// eslint-disable-next-line import/no-unresolved
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = (): Web3ReactContextInterface<Web3Provider> => {
  const networkMode = parseInt(process.env.REACT_APP_NETWORK_MODE)
  const { library, chainId, ...web3React } = useWeb3React()
  const refEth = useRef(library)
  const [provider, setProvider] = useState(library || simpleRpcProvider)

  let index
  if (chainId === undefined) {
    index = parseInt(sessionStorage.getItem('chain_id'))
    if (Number.isNaN(index)) {
      index = ChainId.AVAX_TESTNET
    }
  }
  else {
    index = chainId
  }


  useEffect(() => {
    if (library !== refEth.current) {
      setProvider(library || simpleRpcProvider(index))
      refEth.current = library
    }
  }, [library, index])

  return { library: provider, chainId: index, ...web3React }
}

export default useActiveWeb3React
