import { useWeb3React } from '@web3-react/core'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useInterval from 'hooks/useInterval'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { simpleRpcProvider } from 'utils/providers'
import { setBlock } from '.'
import { State } from '../types'

export const usePollBlockNumber = (refreshTime = 6000) => {
  const dispatch = useAppDispatch()
  const isWindowVisible = useIsWindowVisible()
  const { chainId } = useActiveWeb3React()
  useInterval(
    () => {
      const fetchBlock = async () => {
        try {
          const blockNumber = await simpleRpcProvider(chainId).getBlockNumber()
          dispatch(setBlock(blockNumber))
        } catch(e) {
          console.log(e)
        }
      }

      fetchBlock()
    },
    isWindowVisible ? refreshTime : null,
    true,
  )
}

export const useBlock = () => {
  return useSelector((state: State) => state.block)
}

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}
